import AuthLayout from '@/components/layouts/AuthLayout.vue';
import MainLayout from '@/components/layouts/MainLayout.vue';
import StoreIndex from '@/components/store/StoreIndex.vue';
import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'sign-in',
        name: 'auth.sign_in',
        alias: '/sign-in',
        component: () => import('../components/auth/SignIn.vue'),
        meta: {auth: false},
      },
    ],
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: 'orders',
        component: () => import('../components/orders/OrdersIndex.vue'),
        meta: {auth: true, hasSidebar: true},
        children: [
          {
            path: '',
            name: 'order.index',
            component: () => import('../components/orders/delivery/OrdersIndex.vue'),
            meta: {auth: true, hasSidebar: true},
          },
          {
            path: 'insert',
            name: 'order.insert',
            component: () => import('../components/orders/delivery/insert/InsertOrder.vue'),
            meta: {auth: true, hasSidebar: false, authorizedGroups: ['admin']},
          },
          {
            path: 'horeca',
            name: 'order.horeca.insert',
            component: () => import('../components/orders/delivery/horeca/HorecaOrder.vue'),
            meta: {auth: true, hasSidebar: false, authorizedGroups: ['admin']},
          },
          {
            path: ':orderId',
            name: 'order.view',
            component: () => import('../components/orders/delivery/view/OrderView.vue'),
            meta: {auth: true, hasSidebar: false},
          },
          {
            path: 'bar',
            name: 'order.bar.index',
            component: () => import('../components/orders/bar/BarOrderIndex.vue'),
            meta: {auth: true, hasSidebar: true},
          },
          {
            path: 'bar/:orderId',
            name: 'order.bar.show',
            component: () => import('../components/orders/bar/show/BarOrderShow.vue'),
            props: (route) => ({action: 'edit', orderId: route.params.orderId}),
            meta: {auth: true, hasSidebar: false},
          },
          {
            path: 'bar-checkout',
            name: 'order.bar-checkout.index',
            component: () => import('../components/orders/bar-checkout/BarCheckoutIndex.vue'),
            meta: {auth: true, hasSidebar: true},
          },
          {
            path: 'bar-checkout/:checkoutId',
            name: 'order.bar-checkout.edit',
            component: () => import('../components/orders/bar-checkout/edit/BarCheckoutEdit.vue'),
            meta: {auth: true, hasSidebar: false},
          },
        ],
      },
      {
        path: 'stores',
        name: 'store.index',
        component: () => import('../components/store/StoreList.vue'),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },
      {
        path: 'stores/add',
        name: 'store.add',
        component: () => import('../components/store/AddStore.vue'),
        meta: {auth: true, authorizedGroups: ['admin']},
      },
      {
        path: 'stores/:storeId',
        component: StoreIndex,
        children: [
          {
            path: '',
            name: 'store.info',
            component: () => import('../components/store/StoreInfo.vue'),
            meta: {auth: true, hasSidebar: true, authorizedGroups: ['admin', 'city_manager']},
          },
          {
            path: 'azs',
            name: 'store.azs',
            component: () => import('../components/store/StoreAzs/StoreAzsList.vue'),
            meta: {auth: true, hasSidebar: true, authorizedGroups: ['admin', 'city_manager']},
          },
          {
            path: 'inventory',
            name: 'store.inventory',
            component: () => import('../components/store/StoreInventory/StoreInventory.vue'),
            meta: {auth: true, hasSidebar: true, authorizedGroups: ['admin', 'city_manager']},
          },
          {
            path: 'movements',
            name: 'store.movements',
            component: () => import('../components/store/StockMovements/StockMovementsList.vue'),
            meta: {auth: true, hasSidebar: true, authorizedGroups: ['admin', 'city_manager']},
          },
          {
            path: 'stock-events',
            name: 'store.stock-events',
            component: () => import('../components/store/StockEvents/StockEventsIndex.vue'),
            meta: {auth: true, hasSidebar: true, authorizedGroups: ['admin', 'city_manager']},
          },
        ],
      },
      {
        path: 'stores/:storeId/azs/:azId',
        name: 'store.azs.edit',
        component: () => import('../components/store/StoreAzs/StoreAzEdit.vue'),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },
      {
        path: 'stores/:storeId/movements/create',
        name: 'store.movements.create',
        component: () => import('../components/store/StockMovements/StockMovementCreate.vue'),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },

      {
        path: 'stores/:storeId/movements/:stockMovementId',
        name: 'store.movements.view',
        component: () => import('../components/store/StockMovements/StockMovementView.vue'),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },
      {
        path: 'stores/:storeId/products/:productId',
        name: 'store.product',
        component: () =>
          import(
            '../components/store/StoreInventory/StoreInventoryProduct/StoreInventoryProduct.vue'
          ),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },
      {
        path: 'stores/:storeId/inventory/upsert',
        name: 'store.inventory.upsert',
        component: () => import('../components/store/StoreInventory/BulkUpsert/Page.vue'),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },
      {
        path: 'products',
        component: () => import('../components/products/ProductsIndex.vue'),
        meta: {auth: true, hasSidebar: true},
        children: [
          {
            path: '',
            name: 'product.index',
            component: () => import('../components/products/product/ProductIndex.vue'),
            meta: {auth: true, hasSidebar: true},
          },
          {
            path: 'requests',
            name: 'product.request.index',
            component: () => import('../components/products/request/RequestIndex.vue'),
            meta: {auth: true, hasSidebar: true},
          },
        ],
      },
      {
        path: '/products/create',
        name: 'product.create',
        component: () => import('../components/products/product/ProductEdit.vue'),
        props: () => ({action: 'create'}),
        meta: {auth: true, authorizedGroups: ['admin']},
      },
      {
        path: '/products/duplicate/:id',
        name: 'product.duplicate',
        component: () => import('../components/products/product/ProductEdit.vue'),
        props: (route) => ({action: 'duplicate', productId: route.params.id}),
        meta: {auth: true, authorizedGroups: ['admin']},
      },
      {
        path: '/products/:id',
        name: 'product.edit',
        component: () => import('../components/products/product/ProductEdit.vue'),
        props: (route) => ({action: 'edit', productId: route.params.id}),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },
      {
        path: '/products/requests/create',
        name: 'product.request.create',
        component: () => import('../components/products/request/RequestEdit.vue'),
        props: () => ({action: 'create'}),
        meta: {auth: true, authorizedGroups: ['city_manager']},
      },
      {
        path: '/products/requests/:requestId',
        name: 'product.request.view',
        component: () => import('../components/products/request/RequestEdit.vue'),
        props: (route) => ({action: 'update', requestId: route.params.requestId}),
        meta: {auth: true},
      },
      {
        path: '/suppliers',
        name: 'supplier.index',
        component: () => import('../components/suppliers/SuppliersIndex.vue'),
        meta: {auth: true, authorizedGroups: ['admin']},
      },
      {
        path: '/suppliers/create',
        name: 'supplier.create',
        component: () => import('../components/suppliers/SupplierCreate.vue'),
        meta: {auth: true, authorizedGroups: ['admin']},
      },
      {
        path: '/suppliers/:supplierId',
        name: 'supplier.update',
        component: () => import('../components/suppliers/SupplierUpdate.vue'),
        meta: {auth: true, authorizedGroups: ['admin']},
      },
      {
        path: 'users',
        meta: {
          auth: true,
          hasSidebar: true,
          authorizedGroups: ['admin'],
          authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
        },
        children: [
          {
            path: '',
            component: () => import('../components/users/UsersIndex.vue'),
            meta: {
              auth: true,
              hasSidebar: true,
              authorizedGroups: ['admin', 'city_manager'],
              authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
            },
            children: [
              {
                path: '',
                name: 'user.index',
                component: () => import('../components/users/UserList.vue'),
                meta: {
                  auth: true,
                  hasSidebar: true,
                  authorizedGroups: ['admin', 'city_manager'],
                  authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
                },
              },
              {
                path: '/users/deletion-requests',
                name: 'user.deletionRequests.index',
                component: () =>
                  import('../components/users/deletionRequests/DeletionRequestsIndex.vue'),
                meta: {auth: true, hasSidebar: true, authorizedGroups: ['admin']},
              },
            ],
          },
          {
            path: '/users/:userId',
            component: () => import('../components/users/user/UserIndex.vue'),
            meta: {
              auth: true,
              hasSidebar: false,
              authorizedGroups: ['admin', 'city_manager'],
              authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
            },
            children: [
              {
                path: '',
                name: 'user.view',
                component: () => import('../components/users/user/UserView.vue'),
                meta: {
                  auth: true,
                  hasSidebar: true,
                  authorizedGroups: ['admin', 'city_manager'],
                  authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
                },
              },
              {
                path: 'loyalty',
                name: 'user.view.loyalty',
                component: () => import('../components/users/user/UserViewLoyalty.vue'),
                meta: {
                  auth: true,
                  hasSidebar: true,
                  authorizedGroups: ['admin', 'city_manager'],
                  authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/loyalty',
        component: () => import('../components/loyalty/LoyaltyIndex.vue'),
        meta: {
          auth: true,
          authorizedGroups: ['admin', 'city_manager'],
          authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
        },
        children: [
          {
            path: '/loyalty/redeems',
            name: 'loyalty.redeems',
            component: () => import('../components/loyalty/redeems/RedeemsList.vue'),
            meta: {
              auth: true,
              hasSidebar: true,
              authorizedGroups: ['admin', 'city_manager'],
              authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
            },
          },
          {
            path: '/loyalty/prizes',
            name: 'loyalty.prizes',
            component: () => import('../components/loyalty/prizes/PrizesList.vue'),
            meta: {
              auth: true,
              hasSidebar: true,
              authorizedGroups: ['admin', 'city_manager'],
              authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
            },
          },
          {
            path: '/loyalty/prizes/create',
            name: 'loyalty.prizes.create',
            component: () => import('../components/loyalty/prizes/PrizeCreate.vue'),
            meta: {
              auth: true,
              hasSidebar: true,
              authorizedGroups: ['admin', 'city_manager'],
              authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
            },
          },
          {
            path: '/loyalty/prizes/:prizeId',
            name: 'loyalty.prizes.view',
            component: () => import('../components/loyalty/prizes/PrizeView.vue'),
            meta: {
              auth: true,
              hasSidebar: false,
              authorizedGroups: ['admin', 'city_manager'],
              authorizedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
            },
          },
        ],
      },
      {
        path: '/drivers',
        name: 'driver.index',
        component: () => import('../components/drivers/DriversIndex.vue'),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },
      {
        path: '/drivers/create',
        name: 'driver.create',
        component: () => import('../components/drivers/DriverCreate.vue'),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },
      {
        path: '/drivers/:driverId',
        name: 'driver.update',
        component: () => import('../components/drivers/DriverUpdate.vue'),
        meta: {auth: true, authorizedGroups: ['admin', 'city_manager']},
      },
      {
        path: '/tv',
        component: () => import('../components/tv/TvLayout.vue'),
        children: [
          {
            path: 'videos',
            name: 'tv.video.index',
            component: () => import('../components/tv/video/VideoIndex.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'channels',
            name: 'tv.channel.index',
            component: () => import('../components/tv/channel/ChannelIndex.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'topics',
            name: 'tv.topic.index',
            component: () => import('../components/tv/topic/TopicIndex.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'videos/create',
            name: 'tv.video.create',
            component: () => import('../components/tv/video/VideoEdit.vue'),
            props: () => ({action: 'create'}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'videos/:videoId/edit',
            name: 'tv.video.edit',
            component: () => import('../components/tv/video/VideoEdit.vue'),
            props: (route) => ({action: 'update', videoId: route.params.videoId}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'channels/create',
            name: 'tv.channel.create',
            component: () => import('../components/tv/channel/ChannelEdit.vue'),
            props: () => ({action: 'create'}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'channels/:channelId/edit',
            name: 'tv.channel.edit',
            component: () => import('../components/tv/channel/ChannelEdit.vue'),
            props: (route) => ({action: 'update', channelId: route.params.channelId}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'topics/create',
            name: 'tv.topic.create',
            component: () => import('../components/tv/topic/TopicEdit.vue'),
            props: () => ({action: 'create'}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'topics/:topicId/edit',
            name: 'tv.topic.edit',
            component: () => import('../components/tv/topic/TopicEdit.vue'),
            props: (route) => ({action: 'update', topicId: route.params.topicId}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
        ],
      },
      {
        path: '/credits',
        component: () => import('../components/credits/CreditsIndex.vue'),
        children: [
          {
            path: 'coupons',
            name: 'credits.coupons.index',
            component: () => import('../components/credits/coupons/CouponsIndex.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'coupons/create',
            name: 'credits.coupons.create',
            component: () => import('../components/credits/coupons/CouponCreate.vue'),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'coupons/:couponCode',
            name: 'credits.coupons.update',
            component: () => import('../components/credits/coupons/CouponUpdate.vue'),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'giftcards',
            name: 'credits.giftcards.index',
            component: () => import('../components/credits/giftcards/GiftcardsIndex.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'giftcards/create',
            name: 'credits.giftcards.create',
            component: () => import('../components/credits/giftcards/GiftcardCreate.vue'),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'giftcards/:giftcardId',
            name: 'credits.giftcards.update',
            component: () => import('../components/credits/giftcards/GiftcardUpdate.vue'),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
        ],
      },
      {
        path: '/city-managers',
        name: 'cityManagers',
        component: () => import('../components/cityManagers/Index.vue'),
        meta: {auth: true, authorizedGroups: ['admin']},
      },
      {
        path: '/alerts',
        name: 'alerts',
        component: () => import('@/components/alerts/Index.vue'),
        meta: {auth: true, authorizedGroups: ['admin']},
      },
      {
        path: '/accounting',
        component: () => import('@/components/accounting/Index.vue'),
        children: [
          {
            path: 'core-fees',
            name: 'accounting.coreFees',
            component: () => import('@/components/accounting/CoreFees.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'franchisee-fees',
            name: 'accounting.franchiseeFees',
            component: () => import('@/components/accounting/FranchiseeFees.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'till-fees',
            name: 'accounting.tillFees',
            component: () => import('@/components/accounting/TillFees.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'rider-fees',
            name: 'accounting.riderFees',
            component: () => import('@/components/accounting/RiderFees.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'documents',
            name: 'accounting.documents',
            component: () => import('@/components/accounting/UploadDocuments.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
        ],
      },
      {
        path: '/cms',
        component: () => import('../components/cms/Index.vue'),
        children: [
          {
            path: 'landings',
            name: 'cms.landings.index',
            component: () => import('../components/cms/landings/Index.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'landings/create',
            name: 'cms.landings.create',
            component: () => import('../components/cms/landings/Create.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: false},
          },
          {
            path: 'landings/:slug',
            name: 'cms.landings.update',
            component: () => import('../components/cms/landings/Edit.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: false},
          },
          {
            path: 'sections',
            name: 'cms.sections.index',
            component: () => import('../components/cms/sections/Index.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'sections/create',
            name: 'cms.sections.create',
            component: () => import('../components/cms/sections/Create.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: false},
          },
          {
            path: 'sections/:id',
            name: 'cms.sections.update',
            component: () => import('../components/cms/sections/Edit.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: false},
          },
        ],
      },
      {
        path: '/knowledge-base',
        children: [
          {
            path: '',
            name: 'knowledgeBase.index',
            component: () => import('../components/knowledgeBase/Index.vue'),
            meta: {auth: true, authorizedGroups: ['admin', 'city_manager'], hasSidebar: false},
          },
        ],
      },
      {
        path: '/experiences',
        component: () => import('../components/experience/ExperienceLayout.vue'),
        children: [
          {
            path: 'experiences',
            name: 'experience.experience.index',
            component: () => import('../components/experience/experience/ExperienceIndex.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'experiences/create',
            name: 'experience.experience.create',
            component: () => import('../components/experience/experience/ExperienceEdit.vue'),
            props: () => ({action: 'create'}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'experiences/:experienceId/edit',
            name: 'experience.experience.edit',
            component: () => import('../components/experience/experience/ExperienceEdit.vue'),
            props: (route) => ({action: 'update', experienceId: route.params.experienceId}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'providers',
            name: 'experience.provider.index',
            component: () => import('../components/experience/provider/ProviderIndex.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'providers/create',
            name: 'experience.provider.create',
            component: () => import('../components/experience/provider/ProviderEdit.vue'),
            props: () => ({action: 'create'}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'providers/:providerId/edit',
            name: 'experience.provider.edit',
            component: () => import('../components/experience/provider/ProviderEdit.vue'),
            props: (route) => ({action: 'update', providerId: route.params.providerId}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
          {
            path: 'orders',
            name: 'experience.orders',
            component: () => import('../components/experience/order/OrderIndex.vue'),
            meta: {auth: true, authorizedGroups: ['admin'], hasSidebar: true},
          },
          {
            path: 'orders/:orderId',
            name: 'experience.order.show',
            component: () => import('../components/experience/order/OrderShow.vue'),
            props: (route) => ({orderId: route.params.orderId}),
            meta: {auth: true, authorizedGroups: ['admin']},
          },
        ],
      },
    ],
  },
];

export default routes;
